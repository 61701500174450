import {
  LOADING_SHIPPING_AREAS,
  SET_SHIPPING_AREAS,
  SET_CUSTOMER_ADDRESSES,
  SET_SHIPPING_ADDRESS,
} from "../types/shipping";

const initialState = {
  loading: false,
  shippingAreas: [],
  addresses: [],
  shippingAddress: null,
};

const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_SHIPPING_AREAS:
      return {
        ...state,
        loading: true,
      };
    case SET_SHIPPING_AREAS:
      return {
        ...state,
        loading: false,
        shippingAreas: action.payload,
      };
    case SET_CUSTOMER_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };
    case SET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };
    default:
      return state;
  }
};

export default shippingReducer;
