import {
  SEARCHING_CUSTOMER_DETAILS,
  SET_CUSTOMER_DETAILS,
  SET_CUSTOMER_RESULTS,
  SET_CUSTOMER_NAME,
  LOADING_CUSTOMERS,
  SET_CUSTOMERS,
  SET_CUSTOMER_PHONE,
} from "../types/customer";

import { SUBMITTED_ORDER } from "../types/order";

const initialState = {
  loading: false,
  phoneNumber: "",
  customerDetails: {
    id: 0,
    name: "",
    phone_number: "",
    email: "",
    gender: "",
  },
  customers: {
    loading: false,
    searchResults: [],
    data: [],
  },
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCHING_CUSTOMER_DETAILS:
      return {
        ...state,
        loading: true,
        phoneNumber: action.payload,
        customerDetails: {
          ...state.customerDetails,
          phoneNumber: action.payload,
        },
      };
    case SET_CUSTOMER_PHONE:
      return {
        ...state,
        phoneNumber: action.payload,
        customerDetails: {
          ...state.customerDetails,
          phone_number: action.payload,
        },
      };
    case SET_CUSTOMER_NAME:
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          name: action.payload,
        },
      };
    case SET_CUSTOMER_DETAILS:
      return {
        ...state,
        loading: false,
        customerDetails: action.payload,
        customers: {
          ...state.customers,
          searchResults: [],
        },
      };
    case LOADING_CUSTOMERS:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: true,
        },
      };
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: {
          ...state.customers,
          loading: false,
          data: action.payload,
        },
      };
    case SET_CUSTOMER_RESULTS:
      return {
        ...state,
        customers: {
          ...state.customers,
          searchResults: action.payload,
        },
      };
    case SUBMITTED_ORDER:
      return initialState;
    default:
      return state;
  }
};

export default customerReducer;
