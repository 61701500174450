import axios from "axios";

import {
  SET_CUSTOMER_DETAILS,
  SET_CUSTOMER_RESULTS,
  SET_CUSTOMERS,
} from "../../redux/types/customer";

import { SET_CUSTOMER_ADDRESSES } from "../../redux/types/shipping";

export const getCustomerByPhoneNumber = async (dispatch, phoneNumber) => {
  try {
    const customer = await axios.get(`customers/${phoneNumber}/details`);

    if (customer.data.data.phone_number === "") {
      customer.data.data.phone_number = phoneNumber;
    }

    if (customer.data.data.id) {
      const shippingAddresses = await axios.get(
        `delivery/user/${customer.data.data.id}/delivery-addresses`
      );

      dispatch({
        type: SET_CUSTOMER_ADDRESSES,
        payload: shippingAddresses.data.data,
      });
    }

    dispatch({
      type: SET_CUSTOMER_DETAILS,
      payload: customer.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCustomerByName = async (dispatch, name) => {
  if (name === "") {
    dispatch({
      type: SET_CUSTOMER_RESULTS,
      payload: [],
    });
    return;
  }
  try {
    const customer = await axios.get(`customers/details?name=${name}`);

    dispatch({
      type: SET_CUSTOMER_RESULTS,
      payload: customer.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setCustomers = (customers) => {
  return {
    type: SET_CUSTOMERS,
    payload: customers,
  };
};
