/* eslint-disable import/first */
import React from "react";

import async from "../components/Async";

import { ShoppingCart, Users } from "react-feather";

import { Dashboard as DashboardIcon, Group, Receipt } from "@material-ui/icons";

// Guards
import AuthGuard from "../components/AuthGuard";

// Auth components
import SignIn from "../components/Auth/SignIn";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Dashboards components
const Dashboard = async(() => import("../pages/dashboards"));

// Pages components
import Orders from "../pages/orders/Orders";
import NewSale from "../pages/sales/NewSale";
import Customers from "../pages/customers/Customers";
import NewCustomer from "../pages/customers/NewCustomer";

// Protected routes
import ProtectedPage from "../pages/protected/ProtectedPage";

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/",
  icon: <DashboardIcon />,
  containsHome: true,
  component: Dashboard,
  children: null,
};

const customersRoutes = {
  id: "Customers",
  path: "/customers",
  icon: <Group />,
  component: Customers,
  children: null,
};

const newCustomerRoute = {
  id: "Customers",
  path: "/customer/new",
  icon: <Group />,
  component: NewCustomer,
  children: null,
};

const salesRoutes = {
  id: "Sales",
  path: "/sale/new",
  icon: <Receipt />,
  component: NewSale,
  children: null,
};

const orderRoutes = {
  id: "Orders",
  path: "/orders",
  icon: <ShoppingCart />,
  component: Orders,
  children: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/login",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  newCustomerRoute,
  customersRoutes,
  orderRoutes,
  salesRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  customersRoutes,
  salesRoutes,
  orderRoutes,
];
